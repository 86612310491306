export default {
  error_occurred: 'К сожалению, произошла ошибка. Повторите попытку позже.',
  new_content_available: 'Есть новое обновление страницы, пожалуйста, нажмите «Обновить», чтобы перезагрузить страницу.',
  refresh_button_title: 'Обновить',
  force_reload_message: 'Извините, произошла ошибка. Наша команда уже работает над этим. Пожалуйста, попробуйте перезагрузить сайт через минуту.',
  sign_up: 'Завести аккаунт',
  service: 'Обслуживание',
  employee: 'Работник',
  select_employee: 'Выберите человека',
  employees: 'Сотрудники',
  services: 'Услуги',
  duration: 'Продолжительность',
  from: 'Из',
  select_date: 'Выберите дату',
  no_account_question: 'У вас еще нет аккаунта?',
  name: 'Имя',
  surname: 'Фамилия',
  name_and_surname: 'ФИО',
  phone: 'Телефон',
  password: 'Пароль',
  field_is_required: 'Это поле обязательно к заполнению.',
  value_is_too_long: 'Слишком длинное значение.',
  value_is_too_short: 'Значение слишком короткое.',
  signup_was_successful: 'Регистрация прошла успешно. Вход в панель.',
  signup_has_failed: 'Регистрация не удалась. Повторите попытку позже.',
  email_is_taken: 'Учетная запись с указанным адресом электронной почты уже существует. Если вы забыли пароль, воспользуйтесь функцией напоминания.',
  email_or_password_incorrect: 'Адрес электронной почты или пароль неверны. Если вы забыли пароль, воспользуйтесь функцией напоминания.',
  account_not_found: 'Аккаунт не найден.',
  page_not_found: 'Ой! Страница, которую вы искали, не может быть найдена :(',
  go_back_to_main_page: 'Вернитесь на главную страницу и повторите попытку.',
  bookings: 'Бронирование',
  account: 'Мой счет',
  date: 'Свидание',
  cancel: 'Отмена',
  date_from: 'Дата, с',
  date_to: 'Дата до',
  nothing_found_here: 'Ничего не было найдено.',
  old_password: 'Старый пароль',
  remove: 'Удалить',
  phone_is_invalid: 'Номер телефона указан неверно.',
  book: 'Книга',
  sign_up_2: 'Завести аккаунт',
  log_in: 'Войти',
  your_email_address: 'Ваша электронная почта',
  select: 'Выбирать',
  filters: 'Фильтры',
  expand: 'Расширять',
  collapse: 'Крах',
  bookings_drawer_title: 'Выберите фильтры, чтобы отобразить только те бронирования, которые вы ищете.',
  clear_filters: 'Сбросить фильтры',
  show_more: 'Показать больше',
  dont_you_remember_password: 'Забыли свой пароль?',
  password_information: 'Пароль должен состоять не менее чем из 8 символов, содержать заглавную букву, цифру и специальный символ.',
  forgotten_password_button: 'Отправить ссылку для смены пароля',
  do_you_already_have_an_account: 'У вас уже есть аккаунт?',
  reset_password_title: 'Установить новый пароль',
  reset_password_subtitle: 'Введите новый пароль ниже.',
  reset_password_button: 'Сохраните новый пароль',
  profile: 'Профиль',
  logout: 'Выход',
  cancel_booking: 'Отменить бронирование',
  status_approved: 'Одобренный',
  status_payment: 'Ожидание оплаты',
  status_done: 'Завершенный',
  status_cancelled: 'Отменено',
  cancel_booking_dialog_title: 'Вы хотите отменить бронирование?',
  cancel_booking_dialog_information: 'Это действие не может быть отменено!',
  cancel_booking_dialog_button: 'Да, отменить бронирование',
  my_profile: 'Мой профиль',
  my_data: 'Мои детали',
  upload_new_photo: 'Добавить новую фотографию',
  birthday: 'День рождения',
  save_changes: 'Сохранить изменения',
  phone_number: 'Номер телефона',
  directional: 'Код города',
  save_new_password: 'Сохраните новый пароль',
  new_password: 'Новый пароль',
  password_change: 'Изменить пароль',
  view: 'Вид',
  services_2: 'Услуги',
  accept_terms_and_conditions_1: 'Нажав на вариант ниже, я соглашаюсь с',
  accept_terms_and_conditions_2: 'Условия и положения',
  accept_terms_and_conditions_3: 'и я подтверждаю, что прочитал',
  accept_terms_and_conditions_4: 'Заявление о конфиденциальности.',
  to: 'К',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Войти',
  email_is_incorrect: 'Адрес электронной почты неверен.',
  logged_out: 'Вы вышли из системы',
  booking_dialog_date_is_already_taken: 'Выбранное время недоступно. Не могли бы вы выбрать другое место?',
  data_search: 'Поиск данных. Пожалуйста подождите...',
  no_employees: 'Нет сотрудников',
  no_services: 'Нет услуг',
  no_categories: 'Нет категорий',
  no_bookings: 'Бронирование не найдено.',
  no_subscriptions: 'Подписки не найдены.',
  female: 'Женский',
  male: 'Мужской',
  other: 'Другой',
  no_numeric_in_password: 'В пароле нет цифровых символов.',
  no_lowercase_in_password: 'В пароле нет строчных букв.',
  no_uppercase_in_password: 'В пароле нет заглавной буквы.',
  no_special_character: 'Без спецсимвола.',
  min_n_characters: 'Минимальное количество символов — @{min}.',
  max_n_characters: 'Максимальное количество символов — @{max}.',
  email: 'Эл. почта',
  reset_password_fail_text: 'Пароль не изменен. Ссылка, вероятно, устарела.',
  reset_password_success_title: 'Ваш пароль был изменен.',
  reset_password_success_text: 'Вы можете войти с новым паролем.',
  account_activation: 'Активация аккаунта',
  account_activation_success: 'Аккаунт активирован.',
  account_activation_fail: 'При активации вашей учетной записи возникла проблема. Похоже, что учетная запись уже активна или ссылка больше недействительна.',
  account_activation_processing: 'Активация вашей учетной записи. Пожалуйста подождите...',
  account_activation_resend_title: 'Аккаунт не активирован',
  account_activation_resend_text: 'Активируйте свою учетную запись, нажав на ссылку активации в электронном письме, которое мы отправили вам.',
  account_activation_resend_button: 'Отправь еще раз',
  confirm_send_activate_link_dialog_subtitle: 'Мы отправили электронное письмо со ссылкой для активации на адрес электронной почты, указанный ранее.',
  file_is_too_large: 'Выбранный файл слишком велик.',
  appointment_title: 'Выберите человека, дату и время бронирования',
  others: 'Другой',
  countryLabel: 'Страна',
  requiredMessages: 'Укажите номер телефона.',
  date_of_visit: 'Свидание',
  hour_of_visit: 'Время',
  thank_you_for_booking: 'Ваше бронирование',
  no_free_hours: 'Нет доступных временных интервалов в выбранный день.',
  any_person: 'Случайный выбор',
  password_changed: 'Ваш пароль был изменен',
  incorrect_old_password: 'Старый пароль неверный',
  error404: 'Ошибка 404',
  minutes: 'мин',
  hours: 'час',
  days: 'г',
  ok: 'OK',
  web_page_temporary_disabled: 'Извиняюсь! Эта страница была временно отключена.',
  profile_update_success: 'Ваши данные сохранены.',
  form_is_invalid: 'Форма содержит ошибки. Пожалуйста, проверьте выделенные поля и повторите попытку.',
  search: 'Поиск',
  pay_online_button_title: 'Оплатить онлайн',
  account_not_activated_info: 'Адрес электронной почты не подтвержден. Подтвердите свой адрес электронной почты, перейдя по ссылке в отправленном сообщении.',
  paid: 'Оплаченный',
  payments: 'Платежи',
  payment_service: 'обслуживание',
  payment_date: 'Свидание',
  payment_price: 'Цена',
  payment_success_title: 'Платеж прошел успешно, спасибо!',
  payment_fail_title: 'Платеж не прошел, попробуйте еще раз.',
  pay_again: 'Заплатить снова',
  newsletter_subscribe_success: 'Благодарю вас! Ваш адрес электронной почты подтвержден.',
  newsletter_subscribe_fail: 'Возникла проблема при активации вашего адреса электронной почты. Похоже, адрес электронной почты уже активен или ссылка больше недействительна.',
  newsletter_send_again: 'Отправь еще раз',
  newsletter_send_again_success: 'Ссылка для активации отправлена повторно.',
  send_again_fail: 'Возникла проблема с созданием новой ссылки активации, возможно, ваш адрес электронной почты уже активен или вы только что создали ссылку.',
  newsletter_email_is_used: 'Адрес электронной почты уже есть в списке, если адрес не был подтвержден, мы можем повторно отправить ссылку для активации.',
  newsletter_unsubscribe_success: 'Благодарю вас! Ваш адрес электронной почты был удален.',
  newsletter_unsubscribe_fail: 'Не удалось удалить адрес электронной почты. Похоже, адрес электронной почты уже удален или срок действия ссылки истек.',
  booking_payments: 'Бронирование',
  simple_store_product_transactions: 'Товары',
  payment_type_select_box: 'Выберите тип оплаты',
  payment_description: 'Описание',
  add_to_cart: 'Добавить в корзину',
  remove_from_cart: 'Удалить из корзины',
  save: 'Сохранять',
  bookings_cart_summary: 'Резюме бронирования',
  today: 'Сегодня',
  fill_booking_details_title: 'Введите данные',
  create_an_account_question: 'Создайте учетную запись и отправьте пароль на адрес электронной почты',
  value_is_incorrect: 'Это значение недопустимо.',
  first_available_date: 'Свободные временные интервалы на',
  service_location_1: 'Адрес',
  service_location_2: 'Телефон',
  service_location_3: 'Google Meet',
  service_location_4: 'Другой',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Расположение',
  booking_not_found: 'Бронирование не найдено.',
  booking_canceled: 'Бронирование было отменено.',
  booking_canceled_fail: 'Это бронирование не может быть отменено.',
  day: 'День',
  week: 'Неделя',
  month: 'Месяц',
  year: 'Год',
  booking_plural_1: 'Бронирование',
  booking_plural_2: 'Бронирование',
  booking_plural_3: 'Бронирование',
  booking_plural_4: 'Бронирование',
  booking_plural_5: 'Бронирование',
  day_plural_1: 'День',
  day_plural_2: 'Дни',
  day_plural_3: 'Дни',
  day_plural_4: 'Дни',
  day_plural_5: 'Дни',
  week_plural_1: 'Неделя',
  week_plural_2: 'Недели',
  week_plural_3: 'Недели',
  week_plural_4: 'Недели',
  week_plural_5: 'Недели',
  month_plural_1: 'Месяц',
  month_plural_2: 'Месяцы',
  month_plural_3: 'Месяцы',
  month_plural_4: 'Месяцы',
  month_plural_5: 'Месяцы',
  year_plural_1: 'Год',
  year_plural_2: 'Годы',
  year_plural_3: 'Годы',
  year_plural_4: 'Годы',
  year_plural_5: 'Годы',
  tax: 'Код плательщика НДС',
  tax_prefix: 'Префикс',
  get_company_data: 'Поиск',
  get_company_data_failed: 'Данные не могут быть загружены.',
  company_name: 'Название компании',
  street_and_number: 'Улица и номер',
  postcode: 'Почтовый Код',
  city: 'Город',
  country: 'Страна',
  subscribe: 'Подписывайся',
  recurrent_fee_info: 'Выставляется каждый',
  one_time_fee_info: 'Разовая плата.',
  new_account_title: 'Создать новый аккаунт',
  payment_data_title: 'детали платежа',
  billing_data_checkbox_question: 'Платежные реквизиты',
  confirmation_modal_header: 'Действие требует подтверждения',
  confirmation_modal_warning: 'Вы уверены что хотите продолжить?',
  no_way_to_undo_action: 'Это действие не может быть отменено!',
  delete_payment_method: 'Удалить карту',
  add_payment_method: 'Добавить карту',
  fill_card_details: 'Заполните детали платежа.',
  subscriptions: 'Подписки',
  subscription: 'Подписка',
  invoice_number: 'Счет',
  number: 'Число',
  status: 'Статус',
  details: 'Подробности',
  created_at: 'Созданный',
  subscription_status_initiated: 'Неоплачиваемый',
  subscription_status_active: 'Активный',
  subscription_status_inactive: 'Неактивный',
  subscription_status_canceled: 'Отменено',
  ends_at: 'Заканчивается в',
  ends_at_default: 'Действует до конца расчетного периода',
  select_subscription_to_pay: 'Выберите подписку для оплаты.',
  pay_with_subscription: 'Оплата с подпиской',
  bookings_paid_by_subscription_success_message: 'Оплаченный',
  bookings_not_paid_by_subscription_error_message: 'Бронирование не может быть оплачено выбранной подпиской.',
  stripe: 'Stripe',
  provider_tag: 'Метод',
  card: 'Открытка',
  cash: 'Наличные',
  transfer: 'Передача',
  promo_code_label: 'Код скидки',
  promo_code_invalid_message: 'Предоставленный код скидки недействителен.',
  payment_method_setup_error: 'С вашей картой возникла проблема. Ваш банк отказался авторизовать карту для последующих транзакций. Пожалуйста, свяжитесь с вашим банком.',
  active_subscription_missing: 'Для бронирования этой услуги требуется активная подписка. К сожалению, у вас нет подписки для использования.',
  area_code: 'Код города',
  usage: 'Применение',
  create_account_in_stripe_failed: 'Платежный оператор отклонил ваши данные о подписке. Пожалуйста, проверьте правильность формы (ИНН, номер телефона, адрес электронной почты).',
  additional_auth_required: 'Ваш банк требует дополнительной авторизации',
  general_payment_error: 'Возникла проблема с вашим способом оплаты. Возможно, ваша карта не поддерживает подписки. Пожалуйста, свяжитесь с вашим банком или выберите другой способ оплаты.',
  subscription_set_successfully: 'Подписка настроена правильно.',
  booking_created: 'Новое бронирование',
  booking_updated: 'Изменение бронирования',
  booking_deleted: 'Удаление бронирования',
  booking_reminder: 'Напоминание о бронировании',
  email_notifications: 'Уведомления по электронной почте',
  sms_notifications: 'SMS-уведомления',
  save_user_preferences: 'Сохранить настройки',
  free_spots: 'Осталось мест',
  no: 'Нет',
  yes: 'Да',
  p24_inactive: 'Платежный оператор Przelewy24 использовать нельзя. Пожалуйста, свяжитесь с вашим платежным оператором.',
  stripe_customer_invalid: 'Недопустимый идентификатор клиента Stripe. Способ оплаты не может быть привязан к клиенту.',
  stripe_resource_invalid: 'Выбранный товар недоступен. Пожалуйста, уточняйте наличие товара у платежного оператора.',
  account_blocked_message: 'Ваш аккаунт неактивен. Пожалуйста, свяжитесь с администратором.',
  time_zone: 'Часовой пояс',
  no_spots_left: 'Вы достигли максимального количества доступных мест.',
  employee_auth_required: 'Пожалуйста, войдите в свою учетную запись или используйте другой адрес электронной почты. Учетные записи сотрудников требуют авторизации.',
  subpage_auth_required: 'Пожалуйста, войдите в свою учетную запись, чтобы просмотреть вкладку.',
  gross: 'Валовая сумма.',
  subscription_login_info: 'У вас уже есть аккаунт? Пожалуйста, войдите в систему ниже, чтобы заполнить свои данные.',
  change_date_time: 'Перенести',
  promo_code_checkbox: 'У меня есть код скидки',
  booking_promo_code_label: 'Введите код скидки',
  select_hour: 'Выберите время',
  id: 'Id',
  booking_rescheduled: 'Ваше бронирование было изменено.',
  booking_rescheduled_fail: 'Это бронирование не может быть изменено.',
  max_user_bookings_error: 'Вы превысили максимальное количество бронирований. Пожалуйста, выберите другую услугу или свяжитесь с нами.',
  in_total: 'Сумма',
  company: 'Фирма',
  individual: 'Частное лицо',
  bulk_payment: 'Массовая оплата',
  make_bulk_payment: 'Массовая оплата',
  simple_store_product_confirmation_title: 'Заполните форму покупки',
  select_service_type: 'Выберите вариант услуги',
  add_to_calendar_button_label: 'Добавить в календарь',
  login_to_use_service_with_subscription: 'Пожалуйста, войдите в свою учетную запись, чтобы записаться на прием для этой услуги.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Выбранная дата недоступна. Пожалуйста, выберите другую свободную дату. При бронировании нескольких дат убедитесь, что даты не исключают друг друга.',
  select_location_calendar_warning: 'Выберите местоположение, чтобы загрузить доступное время.',
  selectable_location_online_title: 'Онлайн',
  change: 'Изменить',
  show_less_hours_button: 'Показать меньше часов',
  show_more_hours_button: 'Показать больше часов',
  add_another_button_title: 'Добавить еще один',
  close: 'Закрыть',
  selected_time_slots: 'Выбранные временные интервалы',
  select_location: 'Выберите местоположение',
  captcha_invalid_error_notification: 'Проверка captcha не прошла. Попробуйте перезагрузить страницу, если проблема не исчезнет.',
  verify_code_incorrect_error: 'Введенный код неверен.',
  verify_code_too_many_requests: 'Мы не можем сгенерировать новый код в данный момент. Пожалуйста, попробуйте позже или используйте код, который мы уже отправили.',
  send_again_button_title: 'Отправить снова',
  verify_title: 'Подтвердить',
  verify_code_title: 'Введите код подтверждения',
  verify_code_description: 'Мы отправили 6-значный код подтверждения на вашу электронную почту. Введите его ниже:',
  verification_invalid_error_notification: 'Неверная проверка. Попробуйте позже.',
  too_many_requests: 'Ой! Вы действуете слишком быстро. Пожалуйста, притормозите и попробуйте снова через некоторое время.'
}
